<template>
  <div class="container">
    <div class="title mb-3">
      <button class="btn btn-info" onclick="history.back()">
        <i class="el-icon-d-arrow-left"></i> Quay lại
      </button>
    </div>
    <div v-if="is_powerpoint">
      <VueDocPreview type="office" :url="url" />
    </div>
    <div v-else>
      <iframe
        allowfullscreen="true"
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
        class="ml-5 mb-3"
        id="inlineFrameExample"
        title="Inline Frame Example"
        width="900"
        height="600"
        :src="url">
      </iframe>
    </div>
  </div>
</template>
<script>
import VueDocPreview from "vue-doc-preview";
import { SHOW_DETAIL_LEARNING_SCHEDULE } from "@/core/services/store/classes/classes.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
export default {
  name: "slide-content",
  components: {
    VueDocPreview,
  },
  data() {
    return {
      id: this.$route.params.id,
      learningSchedule: {},
      url: "",
      slide_id: this.$route.query.slide_id,
      start_lesson: this.$route.params.start_lesson,
      is_powerpoint: true, //0-> power, 1 dd
    };
  },
  computed: {
    ...mapGetters["currentUser"],
  },
  created() {
    this.showLearningSchedule();
  },
  mounted() {},
  methods: {
    showLearningSchedule() {
      let payload = {
        schedule_id: this.id,
        classroom_id: this.$route.params.classroom_id,
        start_lesson: this.start_lesson,
        slide_id: this.slide_id,
      };
      this.$store
        .dispatch(SHOW_DETAIL_LEARNING_SCHEDULE, payload)
        .then((res) => {
          this.learningSchedule = res.data;
          let currentUser = this.$store.getters.currentUser;
          let center_ids = currentUser?.center_teacher?.map(item => Number(item.center_id));
          let data = res.data?.media_slide?.find(
            (d) =>
              (this.slide_id == null || d.id == this.slide_id) &&
              center_ids.includes(d.center.id) &&
              d.slide_status === 1
          );
          this.is_powerpoint = data.uri_gooolge_slide == '';
          this.url = this.is_powerpoint ? data?.url : data.uri_gooolge_slide;
          this.$store.dispatch(SET_BREADCRUMB, [
            {
              title: `Slide bài giảng: Buổi ${this.learningSchedule.lesson}`,
              icon: "far fa-tasks",
            },
          ]);
        });
    },
  },
};
</script>
<style scoped></style>
